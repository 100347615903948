<template>
  <div>
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="创建日期">
            <a-range-picker v-decorator="['created_at']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item label="操作人">
            <a-select
              v-decorator="['user_id']"
              placeholder="请选择操作人"
              @focus="fetchUserOptions"
              @load="loadingUserOptions"
              allow-clear
            >
              <a-select-option
                v-for="user in userOptions"
                :key="user.id"
                :value="user.id"
              >
                {{ user.emp_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findTenantUserWithAdminOptions } from '@/api/user'
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'

export default {
  name: 'SearchSmsRecord',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'sms_record_search' }),
      userOptions: [],
      loadingUserOptions: false
    }
  },
  methods: {
    fetchUserOptions() {
      this.loadingUserOptions = true
      findTenantUserWithAdminOptions().then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data
        }
        this.loadingUserOptions = false
      })
    },
    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        created_at_begin: formatRangeBeginDate(fieldsValue.created_at),
        created_at_end: formatRangeEndDate(fieldsValue.created_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>

