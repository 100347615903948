<template>
  <div>
    <search @submit="submitSearch" />
    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showSendModal">发送短信</a-button>
      </a-space>
    </div>
    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <template slot="no" slot-scope="no,record">
        <router-link :to="{ name:'sms_info', params:{id: record.id }}">{{ no }}</router-link>
      </template>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <send
      v-if="isShowSendModal"
      :visible.sync="isShowSendModal"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findTenantSmsRecords } from '@/api/sms_record'
import Search from '@/views/sms_records/tenant/Search'

export default {
  name: 'TenantSmsRecordList',
  components: {
    Pagination,
    Search,
    Send: () => import('@/views/sms_records/tenant/Send')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowSendModal: false,
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '记录编号',
          dataIndex: 'no',
          scopedSlots: { customRender: 'no' }
        },
        {
          title: '短信模版',
          dataIndex: 'template_name'
        },
        {
          title: '发送数量',
          dataIndex: 'total_count'
        },
        {
          title: '成功数量',
          dataIndex: 'success_count'
        },
        {
          title: '失败数量',
          dataIndex: 'fail_count'
        },
        {
          title: '状态',
          dataIndex: 'status'
        },
        {
          title: '操作人',
          dataIndex: 'user_name'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          width: 180,
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        }
      ]
    }
  },
  methods: {
    showSendModal() {
      this.isShowSendModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTenantSmsRecords(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
